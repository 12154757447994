
























































































  import { Component, Vue } from 'vue-property-decorator';
  import MixinEdu from "@/views/education/Mixins/mixin-edu";
  import CusTable from "@/components/table";

  @Component({
	  name: 'icon',
	  mixins: [MixinEdu]
  })
  export default class icon extends Vue {
    constructor(props) {
      super(props);
    };
    private param: any = {
			subjectArr: null,
	  }	
    private tableParam = {
      subject: null,
    }
    private action = process.env.VUE_APP_BASE_URL
    private uploadAction = ''
    private formStyle: any = {}
    private fileList = []
    private removeFileHandle(file, fileList) {
      this.fileList = fileList;
    }
    private uploadSuccess(response, file, fileList) {
      this.fileList = fileList.map(i => {
        i.filePath = i.response && i.response.json.filePath; 
        return i 
      })
    };
    $refs!: { table: CusTable };
    private subjectArr: any = [];
    private AddDialogVisible:boolean = false;
    private subjectChange() {
      let param = {
        subject: this.subjectArr
      }
      this.$refs.table.request(param)
    }
    private subjectOptions = []
    private subjectData() {
		  this.axios.post('/system/dictionary/queryAll', { typeCode: 'SUBJECT' }).then((res: any) => {
        if (res.json && res.json.length) {
          this.subjectOptions = res.json;
          this.$refs.table.request({subject: this.subjectArr})
          }
        })
    }
    private updateSubject: any = {}
    private handleEdit(index, row) {
      this.AddDialogVisible = true;
      this.rowId = row.id;
      this.updateSubject = row;
      this.param.subjectArr = row.iconSubjects.map(e => {
        e.name = e.subjectName
        e.code = e.subject
        return e
      })
    }

    private created() {
      this.subjectData()
    };
    private subjectName = '';
    //rowId存在为编辑
    private saveIcon (rowId) {
      if (!this.fileList) {
        this.$message.warning('请上传图片！');
        return;
      } else if (!this.fileList.every((i: any) => { return i.status === "success"})) {
        this.$message.warning('请等待所有图片上传完成！');
        return;
      }else if (!this.param.subjectArr.length) {
        this.$message.warning('请至少选择一个学科');
        return;
      }else {
        let subjects = this.param.subjectArr.map(e => e.code)
        let subjectNames = this.param.subjectArr.map(e => e.name)
        let fileList = this.fileList.map((e:any) => {
          return Object.assign({}, { 'subjects': subjects, 'subjectNames': subjectNames, 'type': 2, 'filePath': e.filePath })
        }) 
        if (rowId) {
          this.updateSubject.subjects = subjects
          this.updateSubject.subjectNames = subjectNames
          let param = [this.updateSubject]
          this.axios.post('/admin/icon/update', param, { headers: {'Content-Type': 'application/json'} }).then((res: any) => {
            if (res.result) {
              this.$message.success('更新成功');
              this.AddDialogVisible = false
              this.$refs.table.request({subject: this.subjectArr})
            }
          })
        }else {
          this.axios.post('/admin/icon/add', fileList, { headers: {'Content-Type': 'application/json'} }).then((res: any) => {
            if (res.result) {
              this.$message.success('上传成功');
              this.AddDialogVisible = false
              this.$refs.table.request({subject: this.subjectArr})
            }
          })
        }
      }
    }
	  private rowId = '';

		/** 删除 **/
    private async handleDelete(idx, row: never) {
      this.selectedArr = [row];
      this.batchDeleteHandle(this.$refs.table, '/admin/icon/batchDelete');
    };

    /**
     * 停用 && 启用
     */
    private handleChangeStatus(idx, row: never | any) {
      this.selectedArr = [row];
      this.batchChangeStatus((row.status == 1 ? 2 : 1), this.$refs.table, '/admin/icon/off')
    }

    /** 批量 停/启 用 **/
    private batchChangeStatusHandle(status) {
      this.batchChangeStatus(status, this.$refs.table, '/admin/icon/off');
    }

    private selectionChange(ev) {
			this.selectedArr = ev;
    };

  }
